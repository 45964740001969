import {
  FETCH_PLAN_DETAILS,
  FETCH_PLAN_DETAILS_SUCCESS,
  FETCH_PLAN_DETAILS_FAILURE,
} from "../actions/Types";

const initialState = {
  planDetails: [],
  loading: false,
  error: "",
};

export default function PaymentsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PLAN_DETAILS:
      return { ...state, loading: true };
    case FETCH_PLAN_DETAILS_SUCCESS:
      return { ...state, loading: false, planDetails: action.payload };
    case FETCH_PLAN_DETAILS_FAILURE:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
}
