import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RegisterWaiting from '../components/RegisterWaiting';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
}));
const RegistrationPage = () => {
  const classes = useStyles();

  // React.useEffect(() => {
  //   sessionStorage.getItem('validSession') ? sessionStorage.getItem('validSession') : history.push('/404');
  // }, []);
  return (
    <Grid className={classes.root}>
      <RegisterWaiting
        statusText="Rejected"
        captionText="Thank you for registering with TheWatchCloud, a trade group of CloudFlog. Your Application has been rejected due compliance non-fulfillment, kindly resubmit with valid and fulfill compliance. We hope for cooperation"
      />
    </Grid>
  );
};

export default RegistrationPage;
