import React from "react";
import "./App.css";
import {
  createMuiTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles";
import { Provider } from "react-redux";
import MainRouter from "./routers/MainRouter";
import { CssBaseline } from "@material-ui/core";
import store from "./store";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

export let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0A1D3F", //default blue
    },
    secondary: {
      main: "#E3E1E1", //background light
    },
    footerBlack: {
      main: "#2C2C2C", //footer black
    },
    defaultWhite: {
      main: "#FFF",
    },
    defaultBlack: {
      main: "#000",
    },
  },
  typography: {
    fontFamily: "Amethysta",
  },
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <Provider store={store}>
      <PayPalScriptProvider
        options={{
          "client-id":
            "ATwE2e9HcCNDFe7cVUGEFZlZFA0ZKsf3vIFYbuD4iw-z-Oi05DyvOxVttzLbJnwFpvWb9OEBD--YR9hc",
        }}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MainRouter />
        </ThemeProvider>
      </PayPalScriptProvider>
    </Provider>
  );
}

export default App;
