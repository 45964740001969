import React, { useEffect } from "react";
import {
  Grid,
  Card,
  List,
  Button,
  Divider,
  ListItem,
  TextField,
  Typography,
  makeStyles,
  CardActions,
  CardContent,
  ListItemIcon,
} from "@material-ui/core";
import { Image } from "semantic-ui-react";
import { getPlanDetails } from "../actions/payment";
import { useDispatch, useSelector } from "react-redux";
// import watchFace from '../assets/images/watch-face.svg';
import MembershipCard from "../components/reusableComponents/MembershipCard";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.defaultWhite.main,
  },
  mainText: {
    fontWeight: 700,
    fontSize: "300%",
    lineHeight: "150%",
    textAlign: "center",
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down("sm")]: {
      fontSize: "200%",
      lineHeight: "150%",
    },
  },
  sectionContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      padding: "10%",
    },
    // flexDirection: "column",
    justifyContent: "center",
    // minHeight: "100vh",
    padding: "10%",
    backgroundColor: theme.palette.defaultWhite.main,
  },
  captionText: {
    fontFamily: "Damion",
    fontWeight: 400,
    fontSize: "300%",
    color: theme.palette.primary.main,
  },
  card: {
    width: "30%",
    margin: "5%",
    padding: "2%",
    boxShadow: "0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1)",
  },
  cardTitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "150%",
    color: theme.palette.primary.main,
    lineHeight: "150%",
    textAlign: "center",
  },
  cardAmount: {
    fontWeight: 700,
    fontSize: "300%",
    lineHeight: "150%",
    textAlign: "center",
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
  },
  cardText: {
    margin: 10,
    fontWeight: 400,
    fontSize: "100%",
    lineHeight: "150%",
    textAlign: "center",
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
  },
  cardButton: {
    fontSize: 16,
    width: "150px",
    fontWeight: 600,
    border: "1px solid",
    borderRadius: "20px",
    textTransform: "none",
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.defaultWhite.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.defaultWhite.main,
    },
  },
  emailButton: {
    fontSize: 16,
    width: "150px",
    fontWeight: 600,
    marginLeft: "30%",
    border: "1px solid",
    borderRadius: "20px",
    textTransform: "none",
    color: theme.palette.defaultWhite.main,
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.defaultWhite.main,
    "&:hover": {
      backgroundColor: theme.palette.defaultWhite.main,
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      marginTop: "20%",
    },
  },
  emailField: {
    // overflow: 'hidden',
    width: "90%",
    color: theme.palette.defaultWhite.main,
    textTransform: "none",
    borderRadius: "20px",
    border: "1px solid",
    borderColor: theme.palette.defaultWhite.main,
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
  },
  promotionCard: {
    backgroundColor: "#0A1D3F",
    width: "80%",
    // height: '80%',
    marginLeft: "10%",
    marginRight: "10%",
    marginBottom: -500,
    borderRadius: "20px",
    padding: "5%",
    position: "relative",
    top: -400,
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
      width: "100%",
      padding: "10%",
      position: "relative",
      // paddingTop: 0,
      margin: 0,
      top: -150,
      // width: '100%'
    },
  },
  promotionHead: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: "200%",
    color: theme.palette.defaultWhite.main,
    // lineHeight: '150%',
    textAlign: "left",
    marginBottom: "5%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "100%",
      lineHeight: "150%",
    },
  },
  promotionTitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "300%",
    color: theme.palette.defaultWhite.main,
    // lineHeight: '150%',
    textAlign: "left",
    marginBottom: "2%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "150%",
      lineHeight: "150%",
    },
  },
  promotionCaption: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "100%",
    color: theme.palette.defaultWhite.main,
    // lineHeight: '150%',
    textAlign: "left",
    marginBottom: "10%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "100%",
      lineHeight: "150%",
    },
  },
  promotionImage: {
    marginLeft: "10%",
  },
  watchFace: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      position: "relative",
      // margin: '5%'
      // marginBottom: 0
    },
  },
  promotionCardContainer: {
    // [theme.breakpoints.down('md')]:{
    //   width: '100%',
    //   flexDirection: 'row',
    // },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      width: "100%",
      position: "relative",
      // justifyContent: 'center',
      alignItems: "center",
      textAlign: "center",
      // marginBottom: 0
    },
  },
  newsletterText: {
    margin: "5%",
    [theme.breakpoints.down("xs")]: {
      // flexDirection: "column-reverse",
      // width: "100%",
      // position: "relative",
      // justifyContent: 'center',
      alignItems: "center",
      textAlign: "center",
      // marginBottom: 0
    },
  },
  membershipCardContainer: {
    justifyContent: "center",
  },
  //   membershipCardContainer: {
  //     // padding: "10px",
  //     [theme.breakpoints.down("xs")]: {
  //       display: "flex",
  //       // padding: "20px",
  //       width: "100%",
  //       flexDirection: "row",
  //       overflowX: "scroll",
  //     },
  //     display: "flex",
  //     flexDirection: "row",
  //     [theme.breakpoints.up("sm")]: {
  //       justifyContent: "center",
  //     },

  //     // overflowX: "scroll",

  //     // [theme.breakpoints.down("sm")]: {
  //     //   overflowX: 'scroll',
  //     //   flexDirection: "row",
  //     //   width: "100%",
  //     //   position: "relative",
  //     //   // justifyContent: 'center',
  //     //   alignItems: 'center',
  //     //   textAlign: "center",
  //     //   // marginBottom: 0
  //     // },
  //   },
  //   membershipCard: {
  //     minWidth: "30%",
  //     margin: "5%",
  //     [theme.breakpoints.down("xs")]: {
  //       minWidth: "90%",
  //       margin: "5%",
  //     },
  //     display: "flex",
  //     flexDirection: "column",
  //     alignItems: "center",
  //   },
}));

const JoinMembership = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { planDetails } = useSelector((state) => state?.PaymentsReducer);
  const [year, setYear] = React.useState(true);
  const [months, setMonths] = React.useState(false);
  const [months1, setMonths1] = React.useState(false);
  const [planData, setPlanData] = React.useState([]);

  useEffect(() => {
    if (planDetails) {
      let item = [];
      planDetails.map((data) => {
        if (data.plan_validity_in_days == 365 && data.plan_status == "Active") {
          item.push(data);
        }
      });
      setTimeout(() => {
        setPlanData(item);
      }, 500);
    }
  }, [planDetails]);

  useEffect(() => {
    dispatch(getPlanDetails());
  }, [dispatch]);

  const getPlanData = (from) => {
    if (from == "year") {
      setMonths1(false);
      setMonths(false);
      setYear(true);
      let item = [];

      planDetails.map((data) => {
        if (data.plan_validity_in_days == 365 && data.plan_status == "Active") {
          item.push(data);
        }
      });
      setTimeout(() => {
        setPlanData(item);
      }, 500);
    } else if (from == "month") {
      setMonths1(false);
      setMonths(true);
      setYear(false);

      let item = [];
      planDetails.map((data) => {
        if (data.plan_validity_in_days == 180 && data.plan_status == "Active") {
          item.push(data);
        }
      });
      setTimeout(() => {
        setPlanData(item);
      }, 500);
    } else if (from == "month1") {
      setMonths1(true);
      setMonths(false);
      setYear(false);
      let item = [];
      planDetails.map((data) => {
        if (data.plan_validity_in_days == 90 && data.plan_status == "Active") {
          item.push(data);
        }
      });
      setTimeout(() => {
        setPlanData(item);
      }, 500);
    }
  };

  return (
    <Grid className={classes.root}>
      <Grid container direction="column" className={classes.sectionContainer}>
        <Grid item>
          {/* <Card className={classes.promotionCard}>
            <Grid container className={classes.promotionCardContainer}>
              <Grid item>
                <Typography className={classes.promotionHead}>WatchCloud</Typography>
                <Typography className={classes.promotionTitle}>Watch Out For What <br/>You Buy!</Typography>
                <Typography className={classes.promotionCaption}>For the first time, <b>Invest like a Pro!</b></Typography>
                <Button variant="outlined" color="primary" className={classes.cardButton}>Get the App</Button>
              </Grid>
              <Grid item className={classes.promotionImage}>
                <Image 
                  src={watchFace}
                  className={classes.watchFace}
                />
              </Grid>
            </Grid>
          </Card> */}
        </Grid>
        <Grid item>
          <Typography gutterBottom className={classes.mainText}>
            You are already a member of the Free Trial of TheWatchCloud. We are
            in process of defining the pricing structure and shall inform you
            once it is ready. Till then, enjoy the Free Trail and we wish you
            Happy Trading !
          </Typography>
        </Grid>
        <CardActions style={{ justifyContent: "center", flexDirection: "row" }}>
          <Button
            variant="contained"
            className={classes.button}
            color={year ? "primary" : "#fff"}
            onClick={() => getPlanData("year")}
          >
            {"1 Year"}
          </Button>

          <Button
            variant="contained"
            className={classes.button}
            size="medium"
            color={months ? "primary" : "#fff"}
            onClick={() => getPlanData("month")}
          >
            {"6 Month"}
          </Button>

          <Button
            variant="contained"
            className={classes.button}
            color={months1 ? "primary" : "#fff"}
            onClick={() => getPlanData("month1")}
          >
            {"3 Month"}
          </Button>
        </CardActions>
        {/* </Grid> */}
        <Grid container spacing={2} className={classes.membershipCardContainer}>
          {planData.map((data) => {
            console.log("Get Plan DAta>>>>>", data);
            return (
              <Grid item className={classes.membershipCard}>
                <MembershipCard
                  homePage={true}
                  cardTitle={data.plan_name}
                  ///                  cardAmount={data.plan_price}
                  cardAmount={`USD  ${parseInt(data.plan_price)} `}
                  amountPeriod={`${data.plan_validity_in_days} days`}
                  cardCaption={data.description}
                  feature1={data.features}
                  // feature2="Chat with Anyone"
                  // feature3="Manage Product"
                  // feature4="Contact With Admin"
                  // feature5='Lorem ipsum dolor sit amet'
                />
              </Grid>
            );
          })}
          {/* <Grid className={classes.membershipCard}>
            <MembershipCard
              cardTitle="FREE"
              cardAmount="$0"
              amountPeriod="15 days"
              cardCaption="15 Days Trial"
              feature1="Unlimited Post"
              feature2="Chat with Anyone"
              feature3="Manage Product"
              feature4="Contact With Admin"
              // feature5='Lorem ipsum dolor sit amet'
            />
          </Grid> */}
          {/* <MembershipCard 
            cardTitle='FREE'
            cardAmount='$0'
            amountPeriod='30 days'
            cardCaption='For most business that wants to optimize'
            feature1='Lorem ipsum dolor sit amet'
            feature2='Lorem ipsum dolor sit amet'
            feature3='Lorem ipsum dolor sit amet'
            feature4='Lorem ipsum dolor sit amet'
            feature5='Lorem ipsum dolor sit amet'
          /> */}
        </Grid>
        {/* <Grid style={{ padding: '1%' }}>
					<Grid item style={{ marginTop: '20%' }}>
						<Typography gutterBottom className={classes.cardTitle}>
							Newsletter
						</Typography>
					</Grid>
					<Grid item>
						<Typography gutterBottom className={classes.cardText}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
							enim ad minim veniam, quis nostrud exercitation ullamco laboris
							nisi ut aliquip ex ea commodo consequat. .
						</Typography>
					</Grid>
					<Grid container justify='center' className={classes.newsletterText}>
						<Grid item>
							<TextField
								id='filled-textare'
								label='Enter your email'
								variant='outlined'
								size='small'
								multiline
								className={classes.emailField}
							></TextField>
						</Grid>
						<Grid item>
							<Button
								variant='outlined'
								color='primary'
								className={classes.emailButton}
							>
								Subscribe
							</Button>
						</Grid>
					</Grid>
				</Grid> */}
      </Grid>
    </Grid>
  );
};

export default JoinMembership;
