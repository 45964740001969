import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  IconButton,
  Hidden,
} from "@material-ui/core";
import LandingPageAppBar from "./LandingPageAppBar";
import { Image } from "semantic-ui-react";
import video from "../assets/videos/watchcloud-video.mp4";
import { ShopOutlined, Apple } from "@material-ui/icons";
import google from "../assets/images/google.png";
import apple from "../assets/images/apple.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  sectionContainer: {
    overflow: "hidden",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 90%)",
      paddingTop: 0,
      minHeight: "80vh",
      alignItems: "center",
      textAlign: "center",
      // width: '100%'
    },
    [theme.breakpoints.down("md")]: {
      minHeight: "50vh",
    },
    justifyContent: "center",
    minHeight: "100vh",
    padding: "10%",
    backgroundColor: theme.palette.primary.main,
    clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 80%)",
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 30,
    color: theme.palette.defaultWhite.main,
  },
  FormSection: {
    display: "flex",
    flexDirection: "column",
    //justifyContent: "center",
    paddingLeft: "5%",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      textAlign: "center",
      padding: "5%",
      paddingTop: 0,
      // width: '100%'
    },
  },
  ImageSection: {
    overflow: "hidden",
    display: "flex",
    alignItems: "flex-start",
    // margin: '5% 10%',
    padding: "5% 0 10% 10%",
    [theme.breakpoints.down("sm")]: {
      padding: "5%",
      //marginTop: 10,
      // order: 2,
    },
    //marginTop: "60px",
  },
  DashImage: {
    overflow: "hidden",
    // height: "100%",
    // width: '100%',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      position: "relative",
    },
    //margin: "auto",
    //position: "absolute",
  },
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "100%",
    lineHeight: "27px",
    color: theme.palette.defaultWhite.main,
    [theme.breakpoints.down("sm")]: {},
  },
  captionTextBold: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: "bold",
    fontSize: "150%",
    lineHeight: "27px",
    marginTop: "2%",
    color: theme.palette.defaultWhite.main,
    [theme.breakpoints.down("sm")]: {},
  },
  button: {
    width: "150px",
    margin: 5,
  },

  anchor: {
    // order: 3,
  },
  requestDemoMarginBottom: {
    marginBottom: "3em",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1em",
    },
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "300%",
    color: theme.palette.defaultWhite.main,
    lineHeight: "120%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8vw",
      lineHeight: "150%",
    },
  },
  videoStyles: {
    width: "100%",
    // height: '80%',
  },
}));

const Main = () => {
  const classes = useStyles();

  const [storesVisible, setStoresVisible] = useState(false);

  return (
    <Grid>
      <LandingPageAppBar />
      <Grid className={classes.root}>
        <Grid className={classes.sectionContainer}>
          <Grid item xs={12} md={7} className={classes.FormSection}>
            <Grid>
              <Typography gutterBottom className={classes.mainText}>
                TheWatchCloud
              </Typography>
            </Grid>
            {/* <Hidden only="xs"> */}
            <Grid>
              <Typography className={classes.captionText}>
                Our signature launch, TheWatchCloud, is the First Professional
                Luxury Trade Group Network that allows you to instantly connect
                and communicate with luxury watch dealers around the world.
              </Typography>
            </Grid>
            {/* </Hidden> */}
            <Grid className={classes.requestDemoMarginBottom}>
              {/* <TypeWriter messages={msgs} /> */}
            </Grid>
            <Grid>
              <a className={classes.anchor}>
                {/* {storesVisible ? ( */}

                <a href="https://apps.apple.com/us/app/thewatchcloud-trade-group/id1595270206">
                  <Image src={apple} className={classes.button} />
                </a>
                {/* <br /> */}
                <a href="https://play.google.com/store/apps/details?id=com.cloudflog">
                  <Image src={google} className={classes.button} />
                </a>
                {/* <Button
                    href="https://apps.apple.com/us/app/thewatchcloud-trade-group/id1595270206"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    style={{ margin: 15 }}
                    startIcon={<Apple />}
                  >
                    
                  </Button>

                  <Button
                    href="https://play.google.com/store/apps/details?id=com.cloudflog"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<ShopOutlined />}
                  >
                    Google Play
                  </Button> */}

                {/* ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => setStoresVisible((visible) => !visible)}
                  >
                    Get the App
                  </Button>
                )} */}
              </a>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className={classes.ImageSection}>
            {/* <video src={video} controls="controls" loop='loop' autoplay="autoplay" className={classes.videoStyles}/> */}
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/jJLuvQOWsy0"
              title="Your Luxury Trading Group"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            {/* <Image
                // fluid
                src={mainVideo}
                className={classes.DashImage}
              /> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Main;
