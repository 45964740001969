import React from "react";
import { Container } from "@material-ui/core";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  TextField,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { onPaypalSuccess } from "../../actions/payment";
import { useDispatch } from "react-redux";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { useHistory } from "react-router-dom";

const paypal_client = {
  sandbox:
    "AR1gb8BGYHFS_y44ohuOglKOKDfj2fYcnTiO9LP4G7c5UxZVVdUWZvSNXPA24cq-RL1ReRuvmwO0mlZL",
  production:
    "AT8949VZLn8thBLA7v_awK7EekdGUXKgjXrG0RrSQmAbTaIAFhF-yRPAQmhr-XrTmSWkBgm6ipzyWpz4",
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.defaultWhite.main,
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "300%",
    color: theme.palette.primary.main,
    lineHeight: "150%",
    textAlign: "center",
  },
  captionText: {
    fontFamily: "Damion",
    fontWeight: 400,
    fontSize: "300%",
    color: theme.palette.primary.main,
  },
  card: {
    width: "100%",
    padding: "2%",
    boxShadow: "0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1)",
    // margin: '5%',
    [theme.breakpoints.down("sm")]: {
      // flexDirection: "column-reverse",
      width: "100%",
      position: "relative",
      justifyContent: "center",
      // alignItems: 'center',
      textAlign: "center",
      // margin: 'unset',
      // marginBottom: 0
    },
  },
  cardTitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "150%",
    color: theme.palette.primary.main,
    lineHeight: "150%",
    textAlign: "center",
  },
  cardAmount: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "300%",
    color: theme.palette.primary.main,
    lineHeight: "150%",
    textAlign: "center",
  },
  cardText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "100%",
    color: theme.palette.primary.main,
    lineHeight: "150%",
    textAlign: "center",
    margin: 10,
  },
  cardButton: {
    width: "150px",
    color: theme.palette.primary.main,
    textTransform: "none",
    borderRadius: "20px",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    backgroundColor: theme.palette.defaultWhite.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.defaultWhite.main,
    },
  },
}));

const MembershipCard = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const onSuccessPayment = (payment, price) => {
    console.log("The payment was succeeded!", payment, price);
    const body = new Object();
    body.amount = parseInt(price);
    body.plan = "month";
    body.payment_data = payment;

    dispatch(onPaypalSuccess(body));
  };

  const onCancelPayment = (data, price) => {
    console.log("The payment was cancelled!", JSON.stringify(data), price);
  };

  const onErrorPayment = (err) => {
    console.log("Error!", err);
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.cardTitle}>{props.cardTitle}</Typography>
        <Typography className={classes.cardAmount}>
          {props.cardAmount}
        </Typography>
        <Typography className={classes.cardText}>
          {props.amountPeriod}
        </Typography>
        <Typography className={classes.cardText}>
          {props.cardCaption}
        </Typography>
        <Divider />
        <List>
          {props.feature1.map((data) => {
            return (
              <ListItem className={classes.cardText}>
                <CheckCircleIcon
                  color="primary"
                  style={{ marginRight: "2%" }}
                />
                {data.chinese_name}
              </ListItem>
            );
          })}

          {/* <ListItem className={classes.cardText}>
            <CheckCircleIcon color="primary" style={{ marginRight: '2%' }} />
            {props.feature2}
          </ListItem>
          <ListItem className={classes.cardText}>
            <CheckCircleIcon color="Primary" style={{ marginRight: '2%' }} />
            {props.feature3}
          </ListItem>
          <ListItem className={classes.cardText}>
            <CheckCircleIcon color="Primary" style={{ marginRight: '2%' }} />
            {props.feature4}
          </ListItem> */}
          {/* <ListItem className={classes.cardText}>
            <CheckCircleIcon color='error' style={{marginRight:'2%'}}/>
            {props.feature5}
          </ListItem> */}
        </List>
      </CardContent>
      <CardActions style={{ justifyContent: "center" }}>
        {/* {!props.homePage ? ( */}
        {true ? (
          <PaypalExpressBtn
            env={"production"}
            client={paypal_client}
            currency={"USD"}
            total={props.cardAmount}
            onError={onErrorPayment}
            onSuccess={(payment) => onSuccessPayment(payment, props.cardAmount)}
            onCancel={(data) => onCancelPayment(data, props.cardAmount)}
            shipping={1}
            style={{ label: "pay" }}
          />
        ) : (
          <Button
            variant="outlined"
            color="primary"
            className={classes.cardButton}
            onClick={() => history.push("/zh/registration/phoneNumber")}
          >
            Purchase
          </Button>
        )}
      </CardActions>
      <Divider />
      <Typography style={{ fontSize: 10 }} className={classes.cardText}>
        * 您无需成为 PayPal 会员亦可以使用 PayPal 按钮通过信用卡支付
      </Typography>
    </Card>
  );
};

export default MembershipCard;
