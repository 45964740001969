import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { Image } from "semantic-ui-react";
// import chatScreenTop from '../assets/images/chat-screen-top.svg';
import featuresBag from "../assets/images/features-bag.svg";
import featuresPrice from "../assets/images/features-price.svg";
import featuresShop from "../assets/images/features-shop.svg";
import featuresTranslate from "../assets/images/features-translate.svg";
// import chatScreens from '../assets/images/chat-screens.svg';
// import builtInChat from '../assets/images/built-in-chat.svg';
import filters from "../assets/images/filters-ss1.png";
import myShop from "../assets/images/my-shop-ss.png";
import singleChat from "../assets/images/single-chat-ss.svg";
// import translator from "../assets/images/translator-ss.svg";
import translator from "../assets/images/translator-ss1.png";
// import { sin } from 'prelude-ls';
// import filters from '../assets/images/built-in-chat.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.defaultWhite.main,
    // paddingTop: '10vw',
  },
  mainText: {
    paddingTop: "10vw",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: "300%",
    color: theme.palette.primary.main,
    lineHeight: "150%",
    marginBottom: "10%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "200%",
    },
  },
  sectionContainer: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      clipPath: "polygon(0 0, 100% 2%, 100% 100%, 0 100%)",
      paddingTop: "20%",
      alignItems: "center",
      textAlign: "center",
      padding: "10%",
    },
    justifyContent: "center",
    minHeight: "100vh",
    padding: "10%",
    paddingBottom: 0,
    backgroundColor: theme.palette.secondary.main,
    clipPath: "polygon(0 0, 100% 8%, 100% 100%, 0 100%)",
  },
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "150%",
    color: theme.palette.primary.main,
    margin: "2%",
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: "200%",
    color: theme.palette.primary.main,
    lineHeight: "150%",
    margin: "2%",
  },
  featuresBox: {
    display: "flex",
    flexDirection: "column",
    padding: "5%",
    alignItems: "flex-start",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      // textAlign: "center",
      justifyContent: "center",
    },
  },
  chatScreenTop: {
    overflow: "hidden",

    [theme.breakpoints.down("md")]: {
      width: "100%",
      position: "relative",
      top: 0,
    },
  },
  catImages: {
    overflow: "hidden",
    marginTop: "10%",
    height: 700,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      position: "relative",
      top: 0,
    },
  },
  quoteText: {
    fontFamily: "Damion",
    fontWeight: 400,
    fontSize: "300%",
    color: theme.palette.primary.main,
    width: "80%",
    textAlign: "center",
    marginBottom: "10%",
    marginTop: "20%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "200%",
      lineHeight: "100%",
      width: "100%",
      marginTop: "50%",
    },
  },
}));

const OurFeatures = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.root}>
      <Grid container className={classes.sectionContainer}>
        <Typography gutterBottom className={classes.quoteText}>
          "The Revolution of Trading. It’s customized- direct - a new way of
          trading."
        </Typography>
        <Typography gutterBottom className={classes.mainText}>
          Our Features
        </Typography>
        {/* <Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
        </Typography> */}
        {/* <Image src={chatScreenTop} className={classes.chatScreenTop} /> */}
        <Grid container>
          <Grid className={classes.featuresBox} container item xs={12} md={6}>
            <Image src={featuresTranslate} />
            <Typography className={classes.text}>
              Built-in Chat Messenger
            </Typography>
            <Typography className={classes.captionText}>
              TheWatchCloud is{" "}
              <b>
                the easiest and fastest way to instantly connect and communicate
              </b>{" "}
              with Luxury Watch Dealers around the world, with a{" "}
              <b>Built-in Chat Messenger</b> connecting you with all dealers in
              the group.
            </Typography>
            <Grid>
              <Image src={singleChat} className={classes.catImages} />
            </Grid>
          </Grid>
          <Grid className={classes.featuresBox} container item xs={12} md={6}>
            <Image src={featuresTranslate} />
            <Typography className={classes.text}>
              Auto Chat Translator
            </Typography>
            <Typography className={classes.captionText}>
              With our <b>Auto Chat Translator,</b> you can communicate in all
              Languages with Dealers around the world. Expand your trading
              opportunities worldwide.
            </Typography>
            <Grid>
              <Image src={translator} className={classes.catImages} />
            </Grid>
          </Grid>
          <Grid
            className={classes.featuresBox}
            container
            item
            direction="column"
            xs={12}
            md={6}
          >
            <Grid>
              <Image src={featuresPrice} />
            </Grid>
            <Typography className={classes.text}>
              User Friendly Filters
            </Typography>
            <Typography className={classes.captionText}>
              <p>
                To help you customize and find specific trades that match your
                trading needs, our advanced filters are super easy to use. You
                can set the filter by <b>watch brands</b> or <b>price range</b>;
                find
                <b> dealers by location</b> and customize what you see based
                your business needs.
              </p>
            </Typography>
            <Grid>
              <Image src={filters} className={classes.catImages} />
            </Grid>
          </Grid>
          <Grid className={classes.featuresBox} container item xs={12} md={6}>
            <Image src={featuresShop} />
            <Typography className={classes.text}>Inventory shop</Typography>
            <Typography className={classes.captionText}>
              Our revolutionary Inventory Shop concept will automatically let
              dealers know if someone is looking for a watch that you have
              uploaded to your Shop. The APP will automatically send your watch
              offers to the chat group (watch match auto-system) so that you
              don’t need to spend time observing the group to see what dealers
              are looking for.
            </Typography>
            <Grid>
              <Image src={myShop} className={classes.catImages} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OurFeatures;
