import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import PhoneNumberScreen from '../components/PhoneNumberScreen';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
}));
const RegistrationPage = () => {
  const classes = useStyles();

  // React.useEffect(() => {
  //   sessionStorage.getItem('validSession') ? sessionStorage.getItem('validSession') : history.push('/404');
  // }, []);
  return (
    <Grid className={classes.root}>
      <PhoneNumberScreen />
    </Grid>
  );
};

export default RegistrationPage;
