import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import RegisterWaiting from "../components/RegisterWaiting";
import {
  Box,
  Grid,
  Radio,
  Button,
  Backdrop,
  Checkbox,
  FormLabel,
  RadioGroup,
  Typography,
  FormControlLabel,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { useDispatch } from "react-redux";
import { createUser } from "../actions/user";
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { TextField, SimpleFileUpload, InputBase } from "formik-material-ui";
import LandingPageAppBar from "../components/LandingPageAppBar";
import google from "../assets/images/google.png";
import apple from "../assets/images/apple.png";
import { Image } from "semantic-ui-react";
import image1 from "../assets/images/register-screen.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
  formContainer: {
    marginTop: 12,
    padding: "0 30% 0 0",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      width: "100%",
    },
  },
  fieldLabel: {
    color: theme.palette.defaultBlack.main,
    paddingTop: 2,
    paddingBottom: 2,
  },
  inputField: {
    padding: 20,
    // margin: 20,
  },
  inputField1: {
    padding: 20,
    width: 50,
    // margin: 20,
  },
  errorLabel: {
    color: "red",
    paddingTop: 2,
    paddingBottom: 2,
  },
  facePhoto: {
    padding: 15,
    width: "full",
    height: 100,
    border: "1px solid",
  },
  inputFieldCountrySelector: {
    paddingBottom: 15,
    paddingTop: 15,
    marginTop: 20,
    border: 0,
    borderBottom: "1px solid black",
    width: "100%",
    color: "#8c8c8c",
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,

    // [theme.breakpoints.down('sm')]: {
    //   paddingTop: 15,
    // 	width: '100%',
    //   borderBottom: '1px solid black',
    // },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  submitButton: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    marginTop: 8,
    marginBottom: 8,
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  root1: {
    display: "block",
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  sectionContainer: {
    display: "block",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      minHeight: "80vh",
      alignItems: "center",
      textAlign: "center",
    },

    padding: "10%",
    // paddingLeft: '20%'
  },
  gridClass: {
    width: "80vw",
    textAlign: "center",
  },
  FormSection: {
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      textAlign: "center",
      padding: "5%",
      paddingTop: 0,
    },
  },
  button: {
    width: "150px",
    margin: 5,
  },
  ImageSection: {
    display: "flex",
    marginTop: "10%",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {},
  },
  DashImage: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      position: "relative",
    },
  },
  statusText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: "150%",
    lineHeight: "40px",
    width: "80vw",
    textAlign: "center",
    color: theme.palette.primary.main,
    margin: "2% 0",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "150%",
    lineHeight: "27px",
    textAlign: "center",
    width: "80vw",
    color: theme.palette.primary.main,
    margin: "2% 0",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      fontSize: "1rem",
    },
  },

  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "300%",
    textAlign: "center",
    width: "80vw",
    color: theme.palette.primary.main,
    lineHeight: "150%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8vw",
      textAlign: "left",
    },
  },
}));

const RegistrationPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isBusinessChecked, setIsBusinessChecked] = useState("");
  const [isTradeChecked, setIsTradeChecked] = useState("");
  const [error, setError] = useState();
  const [facePhoto, setFacePhoto] = useState(null);
  const [profile_pic, setProfile_pic] = useState(null);
  const [businessCard, setBusinessCard] = useState(null);
  const [internationalWatchTradeShowImg, setInternationalWatchTradeShowImg] =
    useState(null);
  const [countryCode, setCountryCode] = React.useState("");
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);

  const [isShopChecked, setIsShopChecked] = useState(false);
  const [isWebsiteChecked, setIsWebsiteChecked] = useState(false);
  const [isCompanyChecked, setIsCompanyChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onTradeChecked = (event) => setIsTradeChecked(event.target.value);
  const onBusinessChecked = (event) => setIsBusinessChecked(event.target.value);

  const handleSubmit = async (data, actions) => {
    setIsLoading(true);
    console.log("SUBMIT DATA", data);
    setError(null);

    // localStorage.setItem("email", data.email);
    var regiFormData = new FormData();
    regiFormData.append("nameOfTwoDealers", data.securityQues1);
    regiFormData.append("credibleReferences", "");
    // regiFormData.append('securityQues2', {
    //   shop: data.securityQues2Shop,
    //   website: data.securityQues2Website,
    //   company: data.securityQues2Company,
    // });
    regiFormData.append("securityQues2Shop", data.securityQues2Shop);
    regiFormData.append("securityQues2Website", data.securityQues2Website);
    regiFormData.append("securityQues2Company", data.securityQues2Company);
    regiFormData.append("is_member_of_watchtrade", isTradeChecked);
    regiFormData.append("is_on_other_platform", isTradeChecked); // ADDED
    regiFormData.append("trade_group_name", data.tradeGroups);
    regiFormData.append("other_platform_name", data.otherPlatform);
    regiFormData.append("first_name", data.firstName);
    regiFormData.append("last_name", data.lastName);
    regiFormData.append("nickName", data.nickName);
    facePhoto && regiFormData.append("facePhoto", facePhoto, facePhoto?.name);
    profile_pic &&
      regiFormData.append("profile_pic", profile_pic, profile_pic?.name); // for verification purpose
    !!businessCard
      ? regiFormData.append("businessCard", businessCard, businessCard?.name)
      : regiFormData.append("businessCard", businessCard);
    !!businessCard
      ? regiFormData.append(
          "internationalWatchTradeShowImg",
          internationalWatchTradeShowImg,
          internationalWatchTradeShowImg?.name
        )
      : regiFormData.append(
          "internationalWatchTradeShowImg",
          internationalWatchTradeShowImg
        );
    // console.log('Submit', regiFormData.getE);
    for (var pair of regiFormData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    dispatch(createUser(regiFormData, history, setError, setIsLoading));
    actions.setSubmitting(false);
  };

  const handleFacePhoto = ({ target: { files } }) => setFacePhoto(files[0]);
  const handleProfilePic = ({ target: { files } }) => setProfile_pic(files[0]);

  const handleBusinessCard = ({ target: { files } }) =>
    setBusinessCard(files[0]);

  const handleInternationalWatchShow = ({ target: { files } }) =>
    setInternationalWatchTradeShowImg(files[0]);

  // const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const UploadButton = ({ id, value, onChange, acceptFile, selected }) => (
    <label htmlFor={id}>
      <Box
        border={"1px  dashed gray"}
        borderRadius={5}
        width="75%"
        height="auto"
        paddingY={5}
        align="center"
      >
        {!selected ? (
          <CloudUploadIcon style={{ fontSize: 50, color: "gray" }} />
        ) : null}
        <Typography
          style={{
            paddingTop: "2px",
            color: "#ffffff",
            width: "50%",
            height: "2rem",
            backgroundColor: "#232323",
            borderRadius: "5px",
            textAlign: "center",
          }}
        >
          {selected ? selected : "Select File"}
        </Typography>

        <input
          id={id}
          // accept="image/*"
          accept={acceptFile}
          name="facePhoto"
          value={value}
          onChange={onChange}
          style={{ display: "none" }}
          type="file"
        />
      </Box>
    </label>
  );

  return (
    <Grid className={classes.root}>
      <RegisterWaiting
        statusText="Approved"
        captionText="Thank you for registering with The WatchCloud, a trade group of CloudFlog. You can now enter the free trial group. We will contact you to request additional information about your credentials if necessary."
      />

      {/* <LandingPageAppBar registration={true} />
      <Grid className={classes.root1}>
        <Formik
          initialValues={{
            securityQues1: "",
            // securityQues2: { shop: '', website: '', company: '' },
            securityQues2Website: "",
            securityQues2Shop: "",
            securityQues2Company: "",
            tradeGroups: "",
            otherPlatform: "",
          }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, submitForm, setFieldValue, values }) => (
            <Form className={classes.formContainer}>
              <AccordionDetails>
                <Form className={classes.formContainer}>
                  <FormLabel
                    className={classes.fieldLabel}
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                      textAlign: "left",
                    }}
                    component="legend"
                  >
                    Upload your Profile Picture or your Company Logo
                  </FormLabel>
                  <UploadButton
                    // acceptFile="image/*"
                    selected={facePhoto?.name}
                    id="facePhoto"
                    onChange={handleFacePhoto}
                  />
                 
                  <FormLabel
                    className={classes.fieldLabel}
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                      textAlign: "left",
                      lineHeight: 2,
                    }}
                    component="legend"
                  >
                    Upload your profile picture that will be shown to other
                    Members in TheWatchCloud App
                  </FormLabel>
                  <UploadButton
                    // acceptFile="image/*"
                    id="profile_pic"
                    selected={profile_pic?.name}
                    onChange={handleProfilePic}
                  />
                 

                  <Typography className={classes.fieldLabel}>
                    In order to join the group, please provide ONE of the
                    following:{" "}
                  </Typography>
                  <Field
                    label="Please provide 2 names of the Watch Dealers who shall verify your identity, if you don't have an online or physical store"
                    component={TextField}
                    name="securityQues1"
                    value={values.securityQues1}
                    fullWidth
                    type="text"
                    variant="standard"
                    inputProps={{ className: classes.inputField }}
                  />

                  <FormLabel
                    className={classes.fieldLabel}
                    style={{
                      marginTop: 20,
                      textAlign: "left",
                      lineHeight: 1.5,
                    }}
                    component="legend"
                  >
                    Please feel free to add any useful comments or information
                    about your business that shows credentials in the luxury
                    watch business. It will help our team to verify your
                    credentials.
                  </FormLabel>
                  <FormLabel
                    className={classes.fieldLabel}
                    style={{ textAlign: "left" }}
                    component="legend"
                  >
                    Please provide the following information Yes/ No
                  </FormLabel>

                  <Box display="flex" alignItems="center">
                    <Typography color="primary" style={{ marginRight: 10 }}>
                      Shop
                    </Typography>
                    <Typography style={{ color: "#878787" }}>Yes</Typography>
                    <Checkbox
                      color="primary"
                      checked={isShopChecked}
                      onChange={() => setIsShopChecked(true)}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                    <Typography style={{ color: "#878787" }}>No</Typography>
                    <Checkbox
                      color="primary"
                      checked={!isShopChecked}
                      onChange={() => {
                        setFieldValue("securityQues2Shop", "");
                        setIsShopChecked(false);
                      }}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </Box>
                  {isShopChecked ? (
                    <Field
                      label="Shop address"
                      component={TextField}
                      name="securityQues2Shop"
                      value={values.securityQues2Shop}
                      type="text"
                      variant="standard"
                    />
                  ) : null}

                  <Box display="flex" alignItems="center">
                    <Typography color="primary" style={{ marginRight: 10 }}>
                      Website
                    </Typography>
                    <Typography style={{ color: "#878787" }}>Yes</Typography>
                    <Checkbox
                      color="primary"
                      checked={isWebsiteChecked}
                      onChange={() => setIsWebsiteChecked(true)}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                    <Typography style={{ color: "#878787" }}>No</Typography>
                    <Checkbox
                      color="primary"
                      checked={!isWebsiteChecked}
                      onChange={() => {
                        setIsWebsiteChecked(false);
                        setFieldValue("securityQues2Website", "");
                      }}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </Box>
                  {isWebsiteChecked ? (
                    <Field
                      type="text"
                      variant="standard"
                      label="Website URL"
                      component={TextField}
                      name="securityQues2Website"
                      value={values.securityQues2Website}
                    />
                  ) : null}

                  <Box display="flex" alignItems="center">
                    <Typography color="primary" style={{ marginRight: 10 }}>
                      Company
                    </Typography>
                    <Typography style={{ color: "#878787" }}>Yes</Typography>
                    <Checkbox
                      color="primary"
                      checked={isCompanyChecked}
                      onChange={() => setIsCompanyChecked(true)}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                    <Typography style={{ color: "#878787" }}>No</Typography>
                    <Checkbox
                      color="primary"
                      checked={!isCompanyChecked}
                      onChange={() => {
                        setFieldValue("securityQues2Company", "");
                        setIsCompanyChecked(false);
                      }}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </Box>
                  {isCompanyChecked ? (
                    <Field
                      label={"Company address"}
                      component={TextField}
                      name="securityQues2Company"
                      value={values.securityQues2Company}
                      type="text"
                      variant="standard"
                    />
                  ) : null}

                  <FormLabel
                    className={classes.fieldLabel}
                    style={{ marginTop: 20 }}
                    component="legend"
                  >
                    Are you doing business in other platform?
                  </FormLabel>
                  <RadioGroup
                    aria-label="userRole"
                    name="userRole"
                    value={isBusinessChecked}
                    onChange={onBusinessChecked}
                  >
                    <Box alignItems="center" display="flex">
                      <FormControlLabel
                        value={"Yes"}
                        control={<Radio color="primary" />}
                        label="Yes"
                      />
                      <Typography style={{ color: "#878787" }}>
                        (e.g. Ebay, Chrono24)
                      </Typography>
                    </Box>
                    <Field
                      disabled={isBusinessChecked !== "Yes"}
                      label="(if Yes, mention name)"
                      component={TextField}
                      name="otherPlatform"
                      value={values.otherPlatform}
                      fullWidth
                      type="text"
                      variant="standard"
                      inputProps={{ className: classes.inputField }}
                    />
                    <FormControlLabel
                      value={"No"}
                      control={<Radio color="primary" />}
                      label="No"
                    />
                  </RadioGroup>

                  <>
                    <FormLabel
                      className={classes.fieldLabel}
                      style={{ marginTop: 20 }}
                      component="legend"
                    >
                      Upload supporting documents or picture
                    </FormLabel>
                    <Typography style={{ color: "#878787", marginBottom: 20 }}>
                      (e.g. business card, shop pictures, and website
                      screenshot.)
                    </Typography>
                    <UploadButton
                      selected={businessCard?.name}
                      id="businessCard"
                      onChange={handleBusinessCard}
                    />
                  </>

                  <FormLabel
                    className={classes.fieldLabel}
                    style={{ marginTop: 20 }}
                    component="legend"
                  >
                    Are you a member of any international, watch trade show?
                  </FormLabel>
                  <RadioGroup
                    aria-label="userRole"
                    name="userRole"
                    value={isTradeChecked}
                    onChange={onTradeChecked}
                  >
                    <FormControlLabel
                      value={"Yes"}
                      control={<Radio color="primary" />}
                      label="Yes"
                    />
                    <Field
                      disabled={isTradeChecked !== "Yes"}
                      label="(if Yes, mention name and member id if possible)"
                      component={TextField}
                      name="tradeGroups"
                      value={values.tradeGroups}
                      fullWidth
                      type="text"
                      variant="standard"
                      inputProps={{ className: classes.inputField }}
                    />
                    <FormLabel
                      className={classes.fieldLabel}
                      style={{
                        marginTop: 20,
                        marginBottom: 20,
                        textAlign: "left",
                      }}
                      component="legend"
                    >
                      Upload picture of ID
                    </FormLabel>
                    <UploadButton
                      // acceptFile="image/*"
                      selected={internationalWatchTradeShowImg?.name}
                      id="internationalWatchTradeShowImg"
                      onChange={handleInternationalWatchShow}
                    />

                    <FormControlLabel
                      value={"No"}
                      control={<Radio color="primary" />}
                      label="No"
                    />
                  </RadioGroup>
                </Form>
              </AccordionDetails>

              <Typography style={{ paddingTop: 25, paddingBottom: 25 }}>
                If you like to enter the group without filling out the requested
                credentials for now you can jump straight to LETS GO. We will
                contact you in the future to request the necessary credential
                which is necessary to stay on the Network.
              </Typography>
              {error ? (
                <Typography style={{ color: "red" }}>{error}</Typography>
              ) : null}
              <Button
                variant="contained"
                onClick={() => {
                  setIsSubmitPressed(true);
                  submitForm();
                }}
                fullWidth
                className={classes.submitButton}
                disabled={isLoading ? true : false}
              >
                Let's Go
              </Button>
              <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </Form>
          )}
        </Formik>

        <Grid className={classes.sectionContainer}>
          <Grid item xs={12} md={7} className={classes.FormSection}>
            <Grid>
              <Typography gutterBottom className={classes.mainText}>
                Registration
              </Typography>
            </Grid>
            <Grid className={classes.gridClass}>
              <Typography className={classes.statusText}>
                {"Approved"}
              </Typography>
              <Typography className={classes.captionText}>
                {
                  "Thank you for registering with The WatchCloud, a trade group of CloudFlog. You can now enter the free trial group. We will contact you to request additional information about your credentials if necessary."
                }
              </Typography>
              <Typography className={classes.captionText}>
                {"Click on Link to Dowload the Luxury Watch Trading Group App"}
              </Typography>
              <Grid>
                <a href="https://apps.apple.com/us/app/thewatchcloud-trade-group/id1595270206">
                  <Image src={apple} className={classes.button} />
                </a>
                
                <a href="https://play.google.com/store/apps/details?id=com.cloudflog">
                  <Image src={google} className={classes.button} />
                </a>
              </Grid>
            </Grid>
          </Grid>

          <Grid className={classes.ImageSection}>
            <Image
              // fluid
              src={image1}
              className={classes.DashImage}
            />
          </Grid>
        </Grid>
      </Grid> */}
    </Grid>
  );
};

export default RegistrationPage;
