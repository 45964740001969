import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Hidden,
  Typography,
  Drawer,
  Modal,
  Box,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { Image } from "semantic-ui-react";
import logoRectangle from "../assets/images/web-logo.png";
import MenuIcon from "@material-ui/icons/Menu";
import { Apple, ShopOutlined } from "@material-ui/icons";

const ZhLandingPageAppBar = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "20px",
      background:
        props.registration === true
          ? theme.palette.defaultWhite.main
          : theme.palette.primary.main,
      paddingLeft: "5%",
      paddingRight: "5%",
    },
    button: {
      width: "200px",
      backgrounColor:
        props.registration === true
          ? theme.palette.defaultWhite.main
          : theme.palette.primary.main,
      textTransform: "none",
      borderRadius: "20px",
      border: "1px solid white",
      fontSize: 16,
      // backgroundColor: theme.palette.defaultWhite.main,
      "&:hover": {
        backgrounColor:
          props.registration === true
            ? theme.palette.defaultWhite.main
            : theme.palette.primary.main,
      },
      [theme.breakpoints.down("sm")]: {},
    },
    appBarElevations: {
      boxShadow: "none",
    },
    toolbarJustifyBetween: {
      justifyContent: "space-between",
    },
    navLinksGrid: {
      width: "36%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    anchor: {
      color:
        props.registration === true
          ? theme.palette.primary.main
          : theme.palette.defaultWhite.main,
      textDecoration: "none",
      [theme.breakpoints.down("sm")]: {
        // fontSize: '30px',
        // margin:'5%'
      },
    },
    anchorText: {
      color:
        props.registration === true
          ? theme.palette.primary.main
          : theme.palette.defaultWhite.main,
      fontFamily: theme.typography.fontFamily,
      fontSize: "20px",
      fontWeight: 600,
      fontStyle: "normal",
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        margin: "2%",
        textAlign: "left",
      },
    },
    logo: {
      // width: '30%',
      [theme.breakpoints.down("sm")]: {
        width: "50%",
        position: "relative",
      },
    },
    drawerMenu: {
      backgroundColor:
        props.registration === true
          ? theme.palette.defaultWhite.main
          : theme.palette.primary.main,
      // height: '50vh',
      alignItems: "center",
    },
    logoText: {
      color:
        props.registration === true
          ? theme.palette.primary.main
          : theme.palette.defaultWhite.main,
      fontFamily: theme.typography.fontFamily,
      fontSize: "30px",
      fontWeight: 600,
      fontStyle: "normal",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        // margin: '5%',
        textAlign: "left",
      },
    },
    modalStyles: {
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#0A1D3F",
      width: "50%",
      height: "50%",
      display: "flex",
      // padding: '10%',
      top: "25%",
      left: "25%",
      borderRadius: "30px",
      textAlign: "center",
      border: "2px solid white",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        top: "30%",
        left: "0",
        textAlign: "center",
        height: "40%",
        // position: "absolute",
        // top: -100,
        // marginLeft: '5%'
      },
    },
  }));

  const classes = useStyles();
  const [menuState, setMenuState] = React.useState(false);
  const [modalState, setModalState] = React.useState(false);

  const toggleMenu = () => {
    setMenuState(!menuState);
  };

  const toggleModal = () => {
    setModalState(!modalState);
  };

  return (
    <Grid className={classes.root}>
      <AppBar
        className={classes.appBarElevations}
        color="transparent"
        position="static"
      >
        <Toolbar
          disableGutters={true}
          className={classes.toolbarJustifyBetween}
        >
          <Grid item style={{ width: "15%" }}>
            <a href={"https://www.cloudflog.com/"} className={classes.anchor}>
              <Image src={logoRectangle} style={{ width: 200 }} />
            </a>
          </Grid>

          <Hidden smDown>
            <Grid className={classes.navLinksGrid}>
              <a
                href={"https://www.cloudflog.com/zh/"}
                className={classes.anchor}
              >
                <Typography className={classes.anchorText}>首页</Typography>
              </a>
              <a
                href={"https://www.cloudflog.com/our-story"}
                className={classes.anchor}
              >
                <Typography className={classes.anchorText}>关于我们</Typography>
              </a>
              {/* <a href={'/#contactus'} className={classes.anchor}>
								<Typography className={classes.anchorText}>
									Contact Us
								</Typography>
							</a> */}
              <a className={classes.anchor}></a>
              {/* <RouterLink to="/#" style={{ textDecoration: "none" }}> */}
              <Button
                // variant="contained"
                color="primary"
                className={classes.button}
                href={"/zh/registration/phoneNumber"}
              >
                <Typography className={classes.anchorText}>注册</Typography>
              </Button>
              {/* </RouterLink> */}
            </Grid>
          </Hidden>

          <Hidden mdUp>
            <IconButton
              edge="end"
              color="secondary"
              aria-label="menu"
              onClick={toggleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Drawer anchor="top" open={menuState} onClose={toggleMenu}>
              <Grid className={classes.drawerMenu}>
                <a
                  href={"https://www.cloudflog.com/zh/"}
                  className={classes.anchor}
                >
                  <Typography
                    className={classes.anchorText}
                    onClick={toggleMenu}
                  >
                    首页
                  </Typography>
                </a>
                <a
                  href={"https://www.cloudflog.com/our-story"}
                  className={classes.anchor}
                >
                  <Typography
                    className={classes.anchorText}
                    onClick={toggleMenu}
                  >
                    关于我们
                  </Typography>
                </a>
                {/* <a href={'/#contactus'} className={classes.anchor}>
									<Typography
										className={classes.anchorText}
										onClick={toggleMenu}
									>
										Contact Us
									</Typography>
								</a> */}
                {/* <a className={classes.anchor}>
                  <Typography
                    className={classes.anchorText}
                    onClick={toggleModal}
                  >
                    Enter Cloud Flog Luxury <br /> Trade Groups
                  </Typography>
                </a> */}
                {/* <RouterLink to="/freetrial" style={{ textDecoration: "none" }}> */}
                <Button
                  // variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={toggleMenu}
                  href={"/zh/registration/phoneNumber"}
                >
                  <Typography className={classes.anchorText}>注册</Typography>
                </Button>
                {/* </RouterLink> */}
              </Grid>
            </Drawer>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Modal
        open={modalState}
        onClose={toggleModal}
        // className={classes.modalStyles}
      >
        <Grid className={classes.modalStyles}>
          <Box>
            <Typography
              style={{ textAlign: "center" }}
              className={classes.anchorText}
            >
              Download the App and register using OTP Now!!!
            </Typography>

            <Button
              href="https://apps.apple.com/us/app/thewatchcloud-trade-group/id1595270206"
              variant="contained"
              color="primary"
              className={classes.button}
              style={{ margin: 15 }}
              startIcon={<Apple />}
            >
              App Store
            </Button>

            <Button
              href="https://play.google.com/store/apps/details?id=com.cloudflog"
              variant="contained"
              color="primary"
              className={classes.button}
              style={{ margin: 15 }}
              startIcon={<ShopOutlined />}
            >
              Google Play
            </Button>
          </Box>
        </Grid>
      </Modal>
    </Grid>
  );
};

export default ZhLandingPageAppBar;
