import {CardElement, useElements, useStripe, CardNumberElement, CardExpiryElement, CardCvcElement} from "@stripe/react-stripe-js";
import React, {useState} from "react";
import {
  Typography,
  makeStyles,
  Grid,
  Button,
  TextField,
  Backdrop,
  LinearProgress,
  CircularProgress,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import {saveStripeInfo} from '../actions/payment';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: '0 20% 0 0',
    [theme.breakpoints.down("sm")]: {
      padding: '0',
      textAlign: 'left',
      margin: '5%',
    },
  },
  formField:{
    margin: '5% 0'
  },
  fieldLabel: {
    color: "#272343",
    paddingTop: 2,
    paddingBottom: 2,
    margin: '2% 0'
  },
  inputField: {
    // padding: 10,
    // margin:'2%',
  },
  submitButton: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    marginTop: 8,
    marginBottom: 8,
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  
}));


const CheckoutForm = (props) => {
  const [error, setError] = useState(null);
  const [name, setName] = useState('');
  const [duration, setDuration] = useState('month');
  // const [email, setEmail] = useState('');
  // const [amount, setAmount] = useState('');
  const [backdrop, setBackdrop] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();  
  const dispatch = useDispatch();
  const history = useHistory();
  const email = localStorage.getItem('email');
  const amount = localStorage.getItem('Plan');
  const phone = localStorage.getItem('phoneNumber');

// Handle real-time validation errors from the CardElement.
const handleChange = (event) => {
  if (event.error) {
    setError(event.error.message);
  } else {
    setError(null);
  }
};

// Handle form submission.
const handleSubmit = async (event) => {
  console.log(event.preventDefault());
  event.preventDefault();
	const card = elements.getElement(CardElement);
	const {paymentMethod, error} = await stripe.createPaymentMethod({
		type: 'card',
    card: card
  });
  console.log('payment_method_id', paymentMethod.id, email, amount, duration, phone);
  
  var infoFormData = new FormData();
  infoFormData.append('email', email);
  // infoFormData.append('amount', 100*amount);
  infoFormData.append('plan', duration);
  infoFormData.append('mobile_number', phone);
  infoFormData.append('payment_method_id', paymentMethod.id);

  var paymentFormData = new FormData();
  paymentFormData.append('email', email);
  paymentFormData.append('amount', 100*amount);
  paymentFormData.append('plan', duration);
  paymentFormData.append('mobile_number', phone);
  paymentFormData.append('payment_method_id', paymentMethod.id);

  dispatch(saveStripeInfo(infoFormData, paymentFormData, history, setBackdrop, props.toggleSuccessModal, amount));
	console.log('error', error);
};

return (
  <Grid className={classes.formContainer}>
    {/* <Typography className={classes.fieldLabel}>Card Details</Typography> */}
    <TextField
      label="Card Holder Name"
      name="name"
      value={name}
      onChange={(event) => setName(event.target.value)}
      fullWidth
      required={true}
      variant="standard"
      inputProps={{ className: classes.inputField }}
      className={classes.formField}
    />
    <CardElement id="card-element" onChange={handleChange} className={classes.formField} options={{hidePostalCode:true}} />
    <div className="card-errors" role="alert">{error}</div>
    
    {/* <Typography className={classes.fieldLabel}>Card Holder Name</Typography> */}
    <Button
      variant="contained"
      onClick={handleSubmit}
      // fullWidth
      className={classes.submitButton}
    >
      Buy Now
    </Button>
    <Backdrop className={classes.backdrop} open={backdrop}>
      <CircularProgress color="inherit" />
    </Backdrop>
  </Grid>
 );
};
export default CheckoutForm;