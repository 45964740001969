import React from "react";
import { makeStyles, Grid, Typography, Button, Hidden, Backdrop, CircularProgress, Modal, Paper} from "@material-ui/core";
import LandingPageAppBar from "../components/LandingPageAppBar";
import { Image } from "semantic-ui-react";
import TextField from '@material-ui/core/TextField';
import image1 from '../assets/images/checkout-screen.svg';
import { useDispatch } from "react-redux";
import { validateOtp } from '../actions/user';
import { useHistory } from "react-router-dom";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from "@stripe/stripe-js/pure";
import CheckoutForm from "../forms/checkoutForm";
import successIcon from '../assets/images/success-icon.png';

const stripePromise = loadStripe('pk_test_51IZBiySDnWS5Bip2zP1hNOvj3NsL1DGPebHuoai7lr1UzBeCWInJD7dMpbpA5EBdDJst6j0DN6qIENsXLQ9ZPG4p00G8sprqn8');
console.log('stripePromise', stripePromise);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: '#ffffff',
  },
  sectionContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      minHeight: "80vh",
      alignItems: "center",
      textAlign: "center",
    },
    justifyContent: 'center',
		padding: '10%',
    // paddingLeft: '20%'
  },
  FormSection: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      // alignItems: "center",
      // textAlign: "center",
      // padding: "5%",
      paddingTop: 0,
    },
  },
  ImageSection: {
    display: "flex",
    // alignItems: "flex-start",
    // margin: '5%',
    [theme.breakpoints.down("sm")]: {
    },
  },
  DashImage: {
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      position: "relative",
    },
  },
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: '100%',
    lineHeight: '27px',
    color: theme.palette.primary.main,
    margin: '2% 0',
    [theme.breakpoints.down("sm")]: {
      
    },    
  },
  button: {
    width: "50%",
    color: theme.palette.defaultWhite.main,
    borderRadius: "20px",
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  anchor: {
    textDecoration: "none",
    // order: 3,
  },
  requestDemoMarginBottom: {
    marginBottom: "3em",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1em",
    },
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "300%",
    color: theme.palette.primary.main,
    lineHeight: '150%',
    [theme.breakpoints.down("sm")]: {
      fontSize: "10vw",
    },
  },
  phoneInputField:{
    margin: '5% 0',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  successModal:{
    width: '50%',
    margin: 'auto',
    marginTop: '10%',
    textAlign:'center',
    [theme.breakpoints.down("sm")]: {
      width: '80%',
    },
  },
  successPaper:{
    padding: '5% 20%',
    alignItems:'center',
    justifyContent:'center',
    [theme.breakpoints.down("sm")]: {
      padding: '10% 5%',
    },
  }
}));

const CheckoutScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const [successModal, setSuccessModal] = React.useState(false);

  const toggleSuccessModal = () => {
    setSuccessModal(!successModal);
  };

  const handleModalClose = () => {
    toggleSuccessModal();
    history.push("/");
  }
  React.useEffect(() => {
    sessionStorage.getItem('validSession') ? sessionStorage.getItem('validSession') : history.push('/404');
  }, []);

  return (
      <Grid>
        <LandingPageAppBar registration={true} />
        <Grid className={classes.root}>
          <Grid className={classes.sectionContainer}>
          <Grid item xs={12} md={7} className={classes.FormSection}>
              <Grid>
                <Typography gutterBottom className={classes.mainText}>
                  You’re Almost Done
                </Typography>
              </Grid>
              <Grid>
                <Typography className={classes.captionText}>
                  Fill the details of your card carefully.
                </Typography>
              </Grid>
              <Grid>
              <Elements stripe={stripePromise}>
                <CheckoutForm toggleSuccessModal={toggleSuccessModal}/>
              </Elements>
              </Grid>
            </Grid>
            <Hidden smDown>
              <Grid item xs={12} md={6} className={classes.ImageSection}>
                <Image
                  // fluid
                  src={image1}
                  className={classes.DashImage}
                />
              </Grid>
            </Hidden>            
          </Grid>
          <Modal
            open={successModal}
            onClose={handleModalClose}
            className={classes.successModal}
          >
            <Paper className={classes.successPaper}>
              <Image
                src={successIcon}
              />
              <Typography className={classes.mainText}>Payment Done!</Typography>
              <Typography style={{margin:'5% 0'}}>App Link:</Typography>
              {/* <Button className={classes.button} href={}>
                Ok
              </Button> */}
           </Paper>
          </Modal>
        </Grid>
      </Grid>
  );
};

export default CheckoutScreen;