import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    // display: 'flex'
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    // justifyItems: 'center'
  },
  button: {
    marginTop: "2%",
    width: "150px",
    color: theme.palette.defaultWhite.main,
    textTransform: "none",
    borderRadius: "20px",
    border: "1px solid white",
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
  },
}));

const Error = () => {
  const classes = useStyles();
  const history = useHistory();
  // React.useEffect(() => {
  //   sessionStorage.getItem('validSession') ? sessionStorage.getItem('validSession') : history.push('/404');
  // }, []);

  return (
    <Grid className={classes.root}>
      <Grid className={classes.container}>
        <Typography>Error: 404, Page not found</Typography>
        <Button onClick={() => history.push("/")} className={classes.button}>
          Go Home
        </Button>
      </Grid>
    </Grid>
  );
};

export default Error;
