import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { Image } from "semantic-ui-react";
// import workingVideo from '../assets/images/working-video.svg';
// import chatScreens from '../assets/images/chat-screens.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.defaultWhite.main,
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: "300%",
    color: theme.palette.primary.main,
    lineHeight: "150%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      fontSize: "200%",
      lineHeight: "150%",
    },
  },
  sectionContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0 95%)",
      alignItems: "center",
      textAlign: "center",
      padding: "10%",
      paddingBottom: "50%",
    },
    [theme.breakpoints.only("md")]: {
      flexDirection: "column",
      clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0 95%)",
      alignItems: "center",
      textAlign: "center",
      padding: "10%",
      // paddingBottom: '50%'
    },

    justifyContent: "center",
    // flexDirection: "column",
    minHeight: "100vh",
    padding: "10%",
    paddingTop: 0,
    paddingBottom: "20%",
    backgroundColor: theme.palette.secondary.main,
    clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0 90%)",
  },

  captionText: {
    fontFamily: "Damion",
    fontWeight: 400,
    fontSize: "300%",
    color: theme.palette.primary.main,
    width: "80%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "200%",
      lineHeight: "100%",
      width: "100%",
      marginTop: "10%",
    },
  },
  chatScreensImg: {
    overflow: "hidden",

    // position:'relative',
    // top: -150,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      position: "relative",
      // top: -100,
      marginLeft: "5%",
    },
  },
  FormSection: {
    display: "flex",
    flexDirection: "column",
    //justifyContent: "center",
    paddingLeft: "5%",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      textAlign: "center",
      padding: "10%",
      paddingTop: 0,
      marginTop: 0,
      // top: -150
    },
  },
  workingVideo: {
    overflow: "hidden",

    width: "80%",
    height: "60vh",
    margin: "5%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      position: "relative",
      // top: -100,
    },
  },
}));

const Working = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.root}>
      <Grid container className={classes.sectionContainer}>
        <Typography gutterBottom className={classes.mainText}>
          How Does It Work?
        </Typography>
        <Grid item xs={12} md={8} className={classes.workingVideo}>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/K0ebYJ7G4l4"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </Grid>
        {/* <Image 
            src={workingVideo}
            className={classes.workingVideo}
          /> */}
        {/* <Typography gutterBottom className={classes.captionText}>
            "The Revolution of Trading. It’s customized- direct - a new way of trading."
          </Typography> */}
        {/* <Grid>
            <Image 
              src={chatScreens}
              className={classes.chatScreensImg}
            />        
          </Grid> */}
      </Grid>
    </Grid>
  );
};

export default Working;
