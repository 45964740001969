import React from "react";
import Footer from "./Footer";
import { Image } from "semantic-ui-react";
import ZhLandingPageAppBar from "./ZhLandingPageAppBar";
import image1 from "../assets/images/register-screen.svg";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import Android from "../assets/images/Android.png";
import apple from "../assets/images/apple.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  sectionContainer: {
    display: "block",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      minHeight: "80vh",
      alignItems: "center",
      textAlign: "center",
    },

    padding: "10%",
    // paddingLeft: '20%'
  },
  gridClass: {
    width: "80vw",
    textAlign: "center",
  },
  FormSection: {
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      textAlign: "center",
      padding: "5%",
      paddingTop: 0,
    },
  },
  button: {
    width: "150px",
    margin: 5,
  },
  button1: {
    width: "10%",
    margin: 4,
    height: 47,
  },
  ImageSection: {
    display: "flex",
    marginTop: "10%",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {},
  },
  DashImage: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      position: "relative",
    },
  },
  statusText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: "150%",
    lineHeight: "40px",
    width: "80vw",
    textAlign: "center",
    color: theme.palette.primary.main,
    margin: "2% 0",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "150%",
    lineHeight: "27px",
    textAlign: "center",
    width: "80vw",
    color: theme.palette.primary.main,
    margin: "2% 0",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      fontSize: "1rem",
    },
  },

  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "300%",
    textAlign: "center",
    width: "80vw",
    color: theme.palette.primary.main,
    lineHeight: "150%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8vw",
      textAlign: "left",
    },
  },
}));

const Main = ({ statusText = "Pending", captionText = "" }) => {
  const classes = useStyles();

  return (
    <Grid>
      <ZhLandingPageAppBar registration={true} />
      <Grid className={classes.root}>
        <Grid className={classes.sectionContainer}>
          <Grid item xs={12} md={7} className={classes.FormSection}>
            <Grid>
              <Typography gutterBottom className={classes.mainText}>
                注册
              </Typography>
            </Grid>
            <Grid className={classes.gridClass}>
              <Typography className={classes.statusText}>
                {statusText}
              </Typography>
              <Typography className={classes.captionText}>
                {captionText}
              </Typography>
              <Typography className={classes.captionText}>
                {"点选连结下载奢侈手表交易群组应用程式"}
              </Typography>
              <Grid>
                <a href="https://apps.apple.com/us/app/thewatchcloud-trade-group/id1595270206">
                  <Image src={apple} className={classes.button} />
                </a>
                {/* <br /> */}
                <a href="https://member.cloudflog.com/cloudflog.apk">
                  <Image src={Android} className={classes.button1} />
                </a>
              </Grid>
            </Grid>
          </Grid>

          <Grid className={classes.ImageSection}>
            <Image
              // fluid
              src={image1}
              className={classes.DashImage}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Main;
