import React, { forwardRef } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles((theme) => ({
	input: {
		backgroundColor: '#fff',
		// padding: '5% 0'
	},
}));

const PhoneInput = (props, ref) => {
	const classes = useStyles();
	const countryCode = localStorage.getItem('countryCode');
	return (
		<TextField
			{...props}
			InputProps={{
				className: classes.input,
				// startAdornment: (
				// 	<InputAdornment position='start'>{countryCode}</InputAdornment>
				// ),
			}}
			inputRef={ref}
			// fullWidth
			size='small'
			label='Phone Number'
			variant='outlined'
			name='phone'
		/>
	);
};
export default forwardRef(PhoneInput);
