import {
  GET_USER_PROFILE,
  PAYMENT_MSG,
  IS_LOGIN,
  IS_SIGNUP,
} from "../actions/Types";
const initialState = {
  userDetails: [],
  paymentMsg: '',
  isLogin: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case IS_LOGIN:
      return{...state, isLogin: true};
    case IS_SIGNUP:
      return{...state, isLogin: false};
    case GET_USER_PROFILE:
      return { ...state, userDetails: action.payload };
    case PAYMENT_MSG: 
      return{...state, paymentMsg: action.payload}
    default:
      return state;
  }
}
