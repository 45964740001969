import React from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Link,
  ButtonGroup,
} from '@material-ui/core';
import { ShopOutlined, Apple } from '@material-ui/icons';

import { Image } from 'semantic-ui-react';
// import logoWhite from '../assets/images/logo-white.svg';
import logoRectangle from '../assets/images/web-logo.png';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    //justifyContent: 'center',
    padding: '5%',
    paddingBottom: '2%',
    backgroundColor: theme.palette.primary.main,
  },
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: '100%',
    lineHeight: '27px',
    color: theme.palette.defaultWhite.main,
  },
  contactsText: {
    fontFamily: theme.typography.fontFamily,
    // fontWeight: 600,
    fontSize: '100%',
    lineHeight: '27px',
    color: theme.palette.defaultWhite.main,
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: '200%',
    color: theme.palette.defaultWhite.main,
    lineHeight: '150%',
  },
  linksRow: {
    marginRight: '5%',
    marginTop: '2%',
  },
  logo: {
    marginTop: '5%',
    justifyContent: 'flex-start',
    width: '100%',
    // backgroundColor: 'yellow'
  },
  buttonText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: '100%',
    lineHeight: '27px',
    color: theme.palette.defaultWhite.main,
  },
  icon: {
    fontSize: '300%',
    color: theme.palette.defaultWhite.main,
    margin: '2%',
  },
  iconGroup: {
    //marginLeft: '40%',
    width: '50%',
  },
}));

const Footer = ({ id = 'support' }) => {
  const classes = useStyles();

  return (
    <Grid id={id}>
      <Grid className={classes.root}>
        <Grid className={classes.sectionContainer}>
          <Grid>
            <Typography className={classes.mainText}>EXPLORE</Typography>
          </Grid>
          <Grid container>
            <Grid item className={classes.linksRow}>
              <Link href="#" className={classes.captionText}>
                Home
              </Link>
            </Grid>
            <Grid item className={classes.linksRow}>
              <Link href="#" className={classes.captionText}>
                Who We Are
              </Link>
            </Grid>
            <Grid item className={classes.linksRow}>
              {/* <Link href='#' className={classes.captionText}>Contact Us</Link> */}
            </Grid>
            <Grid item className={classes.linksRow}>
              <Link href="#" className={classes.captionText}>
                Pricing
              </Link>
            </Grid>
            <Grid item className={classes.linksRow}>
              <Link href="#" className={classes.captionText}>
                Company
              </Link>
            </Grid>
            <Grid item className={classes.linksRow}>
              <Link href="#" className={classes.captionText}>
                Careers
              </Link>
            </Grid>
          </Grid>

          <Grid container className={classes.logo}>
            <Grid item style={{ width: '15%' }}>
              <Image src={logoRectangle} style={{ width: '100%' }} />
            </Grid>
            <Grid item style={{ marginLeft: '2%' }}>
              <Grid item className={classes.captionText}>
                All rights reserved
              </Grid>
              <Grid item>
                <ButtonGroup
                  variant="text"
                  color="secondary"
                  aria-label="text primary button group"
                >
                  <Button className={classes.buttonText} href="/useTerms">
                    Terms of Use
                  </Button>
                  <Button className={classes.buttonText} href="/privacyPolicy">
                    Privacy Policy
                  </Button>
                  <Button className={classes.buttonText} href="#">
                    Cookies
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>

            <Grid item className={classes.iconGroup}>
              <Grid container justify="flex-end">
                <Grid item className={classes.icon}>
                  <FacebookIcon fontSize="large" />
                </Grid>
                <Grid item className={classes.icon}>
                  <TwitterIcon fontSize="large" />
                </Grid>
                <Grid item className={classes.icon}>
                  <InstagramIcon fontSize="large" />
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item className={classes.linksRow}>
                <Typography>
                  <Grid className={classes.contactsText}>Email:</Grid>
                  <Link href="#" className={classes.contactsText}>
                    {' '}
                    info@cloudflog.com{' '}
                  </Link>
                </Typography>
              </Grid>
              <Grid item className={classes.linksRow}>
                <Typography>
                  <Grid className={classes.contactsText}>Phone:</Grid>
                  <Link href="#" className={classes.contactsText}>
                    {' '}
                    +852 5505 0755{' '}
                  </Link>
                </Typography>
              </Grid>

              <Grid item className={classes.linksRow}>
                <Typography>
                  <Grid className={classes.contactsText}>Location</Grid>
                  <Link href="#" className={classes.contactsText}>
                    {' '}
                    Switzerland, Germany, Hong Kong, Philippines{' '}
                  </Link>
                </Typography>
              </Grid>

              <Grid item className={classes.linksRow}>
                <Typography color="secondary">Download for iOS</Typography>
                <Button
                  href="https://apps.apple.com/us/app/thewatchcloud-trade-group/id1595270206"
                  variant="outlined"
                  color="secondary"
                  startIcon={<Apple />}
                >
                  App Store
                </Button>
              </Grid>

              <Grid item className={classes.linksRow}>
                <Typography color="secondary">Download for android</Typography>
                <Button
                  href="https://play.google.com/store/apps/details?id=com.cloudflog"
                  variant="outlined"
                  color="secondary"
                  startIcon={<ShopOutlined />}
                >
                  Google Play
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
