import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import LandingPageAppBar from "../components/LandingPageAppBar";
import ContainerMain from "./reusableComponents/ContainerMain";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.primary.main,
    
  },
  sectionContainer: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
    justifyContent: 'center',
    minHeight: "80vh",
    zIndex: "5",
		padding: 100,
    clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 75%)',
  },
	text: {
		fontFamily: theme.typography.fontFamily,
		fontSize: 30,
		color: theme.palette.defaultWhite.main,
	}
}));

const ComingSoon = () => {
  const classes = useStyles();

  return (
    <ContainerMain>
      <Grid id="main">
        <LandingPageAppBar />
        <Grid className={classes.root}>
          <Grid className={classes.sectionContainer}>
            <Typography className={classes.text}>Coming Soon!</Typography>
          </Grid>
        </Grid>
      </Grid>
    </ContainerMain>
  );
};

export default ComingSoon;