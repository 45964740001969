import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Link,
  ButtonGroup,
} from "@material-ui/core";
import { Image } from "semantic-ui-react";
import logoRectangle from "../assets/images/logo-rectangle.png";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import LandingPageAppBar from "../components/LandingPageAppBar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    //justifyContent: 'center',
    padding: "5%",
    paddingBottom: "2%",
    backgroundColor: theme.palette.primary.main,
  },
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: "100%",
    lineHeight: "27px",
    color: theme.palette.defaultWhite.main,
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: "200%",
    color: theme.palette.defaultWhite.main,
    lineHeight: "150%",
  },
  linksRow: {
    marginRight: "5%",
    marginTop: "2%",
  },
  logo: {
    marginTop: "5%",
    justifyContent: "flex-start",
    width: "100%",
    // backgroundColor: 'yellow'
  },
  buttonText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "100%",
    lineHeight: "27px",
    color: theme.palette.defaultWhite.main,
  },
  icon: {
    fontSize: "300%",
    color: theme.palette.defaultWhite.main,
    margin: "2%",
  },
  iconGroup: {
    //marginLeft: '40%',
    width: "50%",
  },
}));

const UseTerms = () => {
  const classes = useStyles();

  return (
    <Grid>
      <LandingPageAppBar />

      <Grid className={classes.root}>
        <p dir="ltr">Fees, Refunds, and Charges</p>
        <p dir="ltr">Subscription Fees.</p>
        <p dir="ltr">
          Client (Cloudflog services subscribers) shall pay for all subscription
          fees, and hereby authorizes Cloudflog (and its payment processors) to
          debit Client’s bank account or charge Client’s credit card, as stated
          on Client’s channel subscription and billing page. The client agrees
          that all subscriptions are recurring and will renew automatically.
        </p>
        <p dir="ltr">Cancellation Policy.</p>
        <p dir="ltr">
          Client (Cloudflog services subscribers) acknowledges that all
          subscription fees are charged automatically on a recurring basis until
          the Client cancels their channel subscription (both month-to-month and
          annual plans). The client may cancel their subscription through email.
          A confirmation email receipt is sent to the Client with the expiration
          date of the subscription. Please keep in mind that you are solely
          responsible for properly canceling your account. You must email
          support before or on the payment due date. However, you can also
          contact support if you are having difficulty or need help.
        </p>
        <p dir="ltr">Refunds.</p>
        <p dir="ltr">
          All Cloudflog subscriptions include a 3-day free trial with
          observation mode only. After 3 days, the chosen Plan Service is billed
          in advance on a monthly or annual basis and is non-refundable; no
          refunds will be issued. Cloudflog does not offer prorated refunds for
          canceled subscription plans. There will be no refunds or credits for
          partial months of service, upgrade/downgrade refunds, or refunds for
          months unused with an open account. In order to treat everyone
          equally, no exceptions will be made.
        </p>
        <p dir="ltr">
          All subscriptions, both month-to-month and annual plans, are recurring
          and will automatically renew after the end of each paid subscription
          period.
        </p>
        <br />

        <Grid className={classes.sectionContainer}>
          <Grid>
            <Typography className={classes.mainText}>EXPLORE</Typography>
          </Grid>
          <Grid container>
            <Grid item className={classes.linksRow}>
              <Link href="#" className={classes.captionText}>
                Home
              </Link>
            </Grid>
            <Grid item className={classes.linksRow}>
              <Link href="#" className={classes.captionText}>
                Who We Are
              </Link>
            </Grid>
            <Grid item className={classes.linksRow}>
              {/* <Link href='#' className={classes.captionText}>Contact Us by</Link> */}
            </Grid>
            <Grid item className={classes.linksRow}>
              <Link href="#" className={classes.captionText}>
                Pricing
              </Link>
            </Grid>
            <Grid item className={classes.linksRow}>
              <Link href="#" className={classes.captionText}>
                Company
              </Link>
            </Grid>
            <Grid item className={classes.linksRow}>
              <Link href="#" className={classes.captionText}>
                Careers
              </Link>
            </Grid>
          </Grid>
          <Grid container className={classes.logo}>
            <Grid item style={{ width: "15%" }}>
              <Image src={logoRectangle} style={{ width: "100%" }} />
            </Grid>
            <Grid item style={{ marginLeft: "2%" }}>
              <Grid item className={classes.captionText}>
                All rights reserved
              </Grid>
              <Grid item>
                <ButtonGroup
                  variant="text"
                  color="secondary"
                  aria-label="text primary button group"
                >
                  <Button className={classes.buttonText} href="/useTerms">
                    Terms of Use
                  </Button>
                  <Button className={classes.buttonText} href="/privacyPolicy">
                    Privacy Policy
                  </Button>
                  <Button className={classes.buttonText} href="#">
                    Cookies
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            <Grid item className={classes.iconGroup}>
              <Grid container justify="flex-end">
                <Grid item className={classes.icon}>
                  <FacebookIcon fontSize="large" />
                </Grid>
                <Grid item className={classes.icon}>
                  <TwitterIcon fontSize="large" />
                </Grid>
                <Grid item className={classes.icon}>
                  <InstagramIcon fontSize="large" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UseTerms;
