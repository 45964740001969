import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ComingSoon from '../components/ComingSoon';
import Main from '../components/Main';
import WhyUs from '../components/WhyUs';
import Working from '../components/Working';
import JoinMembership from '../components/JoinMembership';
import Footer from '../components/Footer';
import OurFeatures from '../components/OurFeatures';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const LandingPage = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.root}>
      {/* <ComingSoon /> */}
      <Main />
      <WhyUs />
      <OurFeatures />
      <Working />
      <JoinMembership />
      <Footer id="support" />
    </Grid>
  );
};

export default LandingPage;
