import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Hidden,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import LandingPageAppBar from "./LandingPageAppBar";
import { Image } from "semantic-ui-react";
import TextField from "@material-ui/core/TextField";
import image1 from "../assets/images/phone-number-screen.svg";
import { useDispatch } from "react-redux";
import { getOtp } from "../actions/user";
import { useHistory } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import CustomPhoneInput from "./reusableComponents/CustomPhoneInput";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  sectionContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      minHeight: "80vh",
      alignItems: "center",
      textAlign: "center",
    },
    justifyContent: "center",
    padding: "10%",
    // paddingLeft: '20%'
  },
  FormSection: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      textAlign: "center",
      padding: "5%",
      paddingTop: 0,
    },
  },
  ImageSection: {
    display: "flex",
    // alignItems: "flex-start",
    // margin: '5%',
    [theme.breakpoints.down("sm")]: {},
  },
  DashImage: {
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      position: "relative",
    },
  },
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "120%",
    lineHeight: "27px",
    color: theme.palette.primary.main,
    margin: "2% 0",
    [theme.breakpoints.down("sm")]: {},
  },
  button: {
    width: "50%",
    color: theme.palette.defaultWhite.main,
    borderRadius: "20px",
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  anchor: {
    textDecoration: "none",
    // order: 3,
  },
  requestDemoMarginBottom: {
    marginBottom: "3em",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1em",
    },
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "300%",
    color: theme.palette.primary.main,
    lineHeight: "150%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10vw",
    },
  },
  phoneInputField: {
    marginTop: "5%",
    width: "250px",
    // height: '50px'
  },
  textInput: {
    width: 250,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Main = () => {
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [value, setValue] = React.useState("email");
  const [submitting, setSubmitting] = React.useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  // console.log('phoneNumber', phoneNumber);

  const handleSubmit = () => {
    console.log("Get ID::::", emailId);
    // const mobile = '+91'+ phoneNumber;
    var bodyFormData = new FormData();
    bodyFormData.append("mobile_number", phoneNumber);
    bodyFormData.append("email_id", emailId);
    dispatch(getOtp(bodyFormData, history, setSubmitting));
    {
      phoneNumber != ""
        ? localStorage.setItem("phoneNumber", phoneNumber)
        : localStorage.setItem("phoneNumber", "");
    }
    {
      emailId != ""
        ? localStorage.setItem("email", emailId)
        : localStorage.setItem("email", "");
    }
    localStorage.setItem("countryCode", countryCode);
    sessionStorage.setItem("validSession", true);
  };

  // const phoneInput = (props,ref) => {
  //   console.log(props.value, ref);
  //   return <TextField
  //             value={props.value}
  //             // onChange={(event) => setPhoneNumber(event.target.value)}
  //             onChange={props.onChange}
  //             // placeholder="Enter phone number"
  //             placeholder={props.placeholder}
  //           />
  //   }
  const handleChange = (event) => {
    setEmailId(event.target.value);
  };
  const handleChange1 = (event) => {
    setValue(event.target.value);
  };

  return (
    <Grid>
      <LandingPageAppBar registration={true} />
      <Grid className={classes.root}>
        <Grid className={classes.sectionContainer}>
          <Grid item xs={12} md={7} className={classes.FormSection}>
            <Grid>
              <Typography gutterBottom className={classes.mainText}>
                Welcome To <br />
                TheWatchCloud
              </Typography>
            </Grid>
            <Grid>
              <Typography className={classes.captionText}>
                To set up your account, please input your phone number or Email
                Id and start TheWatchCloud app registration process on our
                website. Fill out the Form to join the Trade Member Club, which
                you shall gain access to the TheWatchCloud Trading app.
              </Typography>
            </Grid>

            <FormLabel component="legend">Get started with</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={value}
              onChange={handleChange1}
            >
              <FormControlLabel
                value="mobile"
                control={<Radio />}
                label="Mobile Number"
              />
              <FormControlLabel
                value="email"
                control={<Radio />}
                label="Email Id"
              />
            </RadioGroup>

            <Grid className={classes.requestDemoMarginBottom}>
              {value === "mobile" ? (
                <PhoneInput
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  className={classes.phoneInputField}
                  inputComponent={CustomPhoneInput}
                  defaultCountry="HK"
                  addInternationalOption={false}
                  onCountryChange={setCountryCode}
                  withCountryCallingCode
                  international
                />
              ) : (
                <TextField
                  id="outlined-uncontrolled"
                  label="Email"
                  variant="outlined"
                  margin="normal"
                  size="small"
                  value={emailId}
                  onChange={handleChange}
                  className={classes.textInput}
                />
              )}
            </Grid>
            <Grid>
              <a
                // href={"#virucontact"}
                className={classes.anchor}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleSubmit}
                  disabled={emailId === "" && phoneNumber === "" ? true : false}
                  // href='/registration/otp'
                >
                  Next
                </Button>
              </a>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} md={6} className={classes.ImageSection}>
              <Image
                // fluid
                src={image1}
                className={classes.DashImage}
              />
            </Grid>
          </Hidden>
          <Backdrop className={classes.backdrop} open={submitting}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Main;
