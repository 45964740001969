import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  List,
  ListItem,
} from "@material-ui/core";
import { Image } from "semantic-ui-react";
import chattingSs from "../assets/images/chatting-ss.svg";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: '20vw',
    paddingTop: "10vw",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  sectionContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      //clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 90%)',
      // justifyContent: 'flex-start',
      width: "100%",
      alignItems: "center",
      textAlign: "center",
    },
    justifyContent: "center",
    //minHeight: "100vh",
    //padding: '10%',
    backgroundColor: theme.palette.defaultWhite.main,
    //clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 80%)',
  },
  text: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "100%",
    lineHeight: "24px",
    color: theme.palette.primary.main,
  },
  FormSection: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    paddingLeft: "5%",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      textAlign: "center",
      padding: "10%",
      paddingTop: 0,
      marginTop: 0,
      top: -150,
    },
  },
  ImageSection: {
    // backgroundColor: 'red',
    display: "flex",
    alignItems: "flex-start",
    //padding: '5%',
    [theme.breakpoints.down("md")]: {
      //marginTop: 10,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      position: "relative",
      marginBottom: 0,
    },
    //marginTop: "60px",
  },
  DashImage: {
    // height: "100%",
    // width: '100%',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      position: "relative",
    },
    //margin: "auto",
    //position: "absolute",
  },
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "120%",
    lineHeight: "28px",
    color: theme.palette.primary.main,
    marginTop: "5%",
    marginRight: "10%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0",
      marginTop: "5%",
      textAlign: "center",
    },
  },
  bulletText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: "bold",
    fontSize: "150%",
    lineHeight: "28px",
    color: theme.palette.primary.main,
    textAlign: "left",
    // justifyContent: 'center',
    // marginTop: '5%',
    marginLeft: "5%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "120%",
      width: "100%",
      // margin: '0',
      // marginLeft: '%',
      textAlign: "left",
    },
  },
  button: {
    width: "150px",
    color: theme.palette.primary.main,
    textTransform: "none",
    borderRadius: "20px",
    border: "1px solid white",
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    backgroundColor: theme.palette.defaultWhite.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  anchor: {
    textDecoration: "none",
  },
  requestDemoMarginBottom: {
    marginBottom: "3em",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1em",
    },
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: "300%",
    color: theme.palette.primary.main,
    lineHeight: "150%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "200%",
      lineHeight: "150%",
    },
  },
  whyUsBox: {
    padding: "5%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      // justifyContent: 'center',
    },
    //margin: '10px',
  },
  groupChatImg: {
    position: "relative",
    top: -150,
    width: "50%",
  },
  privateChatImg: {
    width: "50%",
  },
  groupPrivateChatImg: {
    overflow: "hidden",
    position: "relative",
    top: -150,
    marginBottom: -200,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      position: "relative",
      top: 0,
      marginBottom: 0,
    },
  },
  checkIcon: {
    color: theme.palette.primary.main,
    // fontSize: 'large'
  },
}));

const Main = () => {
  const classes = useStyles();

  return (
    <Grid id="aboutus">
      <Grid className={classes.root}>
        <Grid className={classes.sectionContainer}>
          <Grid item xs={12} md={7} className={classes.FormSection}>
            <Grid>
              <Typography gutterBottom className={classes.mainText}>
                Why Choose Us?
              </Typography>
              <Typography className={classes.captionText}>
                TheWatchCloud is{" "}
                <b>the First Professional Luxury Trade Group Network</b> which
                allows you to <b>instantly connect and communicate</b> with
                luxury watch dealers around the world.
              </Typography>
            </Grid>
            {/* <Grid container> */}
            <List style={{ width: "100%" }}>
              <ListItem>
                <CheckIcon className={classes.checkIcon} fontSize="large" />
                <Typography className={classes.bulletText}>
                  Instant Messager
                </Typography>
              </ListItem>
              <ListItem>
                <CheckIcon className={classes.checkIcon} fontSize="large" />
                <Typography className={classes.bulletText}>
                  Built in Translator
                </Typography>
              </ListItem>
              <ListItem>
                <CheckIcon className={classes.checkIcon} fontSize="large" />
                <Typography className={classes.bulletText}>
                  Filter for watches and Locations
                </Typography>
              </ListItem>
              <ListItem>
                <CheckIcon className={classes.checkIcon} fontSize="large" />
                <Typography className={classes.bulletText}>
                  Automatic group match from your inventory shop
                </Typography>
              </ListItem>
              <ListItem>
                <CheckIcon className={classes.checkIcon} fontSize="large" />
                <Typography className={classes.bulletText}>
                  Escrow Secure Trade Services
                </Typography>
              </ListItem>
            </List>
            {/* </Grid> */}
          </Grid>

          {/* <Grid className={classes.whyUsBox} container item xs={12} md={6}>
                  <Image
                    src={whiteSquare}
                  />
                  <Typography className={classes.text}>
                    Lorem ipsum dolor sit amet, consectetur 
                  </Typography>
                  <Typography className={classes.captionText}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Scelerisque diam potenti
                  </Typography>
                </Grid>
                <Grid className={classes.whyUsBox} container item xs={12} md={6}>
                  <Image
                    src={whiteSquare}
                  />
                  <Typography className={classes.text}>
                    Lorem ipsum dolor sit amet, consectetur 
                  </Typography>
                  <Typography className={classes.captionText}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Scelerisque diam potenti
                  </Typography>
                </Grid>
                <Grid className={classes.whyUsBox} container item xs={12} md={6}>
                  <Image
                    src={whiteSquare}
                  />
                  <Typography className={classes.text}>
                    Lorem ipsum dolor sit amet, consectetur 
                  </Typography>
                  <Typography className={classes.captionText}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Scelerisque diam potenti
                  </Typography>
                </Grid>
                <Grid className={classes.whyUsBox} container item xs={12} md={6}>
                  <Image
                    src={whiteSquare}
                  />
                  <Typography className={classes.text}>
                    Lorem ipsum dolor sit amet, consectetur 
                  </Typography>
                  <Typography className={classes.captionText}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Scelerisque diam potenti
                  </Typography>
                </Grid> */}
          <Grid item xs={12} md={6} className={classes.ImageSection}>
            <Image src={chattingSs} className={classes.groupPrivateChatImg} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Main;
