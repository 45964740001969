import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ZhRegisterWaiting from "../components/ZhRegisterWaiting";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const ZhRegistrationPage = () => {
  const classes = useStyles();

  // React.useEffect(() => {
  //   sessionStorage.getItem('validSession') ? sessionStorage.getItem('validSession') : history.push('/404');
  // }, []);
  return (
    <Grid className={classes.root}>
      <ZhRegisterWaiting
        statusText="已批准"
        captionText="感谢您在CloudFlog的贸易群组 - TheWatchCloud注册。 您现在可以免费试用。 如有必要，我们将与您联络，并要求提供更多有关您的凭证的资讯。"
      />
    </Grid>
  );
};

export default ZhRegistrationPage;
