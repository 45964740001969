import axios from "axios";
import {
  PAYMENT_MSG,
  FETCH_PLAN_DETAILS,
  FETCH_PLAN_DETAILS_SUCCESS,
  FETCH_PLAN_DETAILS_FAILURE,
} from "./Types";
var token;

export const saveStripeInfo =
  (
    infoFormData,
    paymentFormData,
    history,
    setBackdrop,
    toggleSuccessModal,
    amount
  ) =>
  (dispatch) => {
    token = localStorage.getItem("accessToken");
    setBackdrop(true);
    console.log("saveStripeInfo", infoFormData);
    return axios
      .post(
        "https://admin.cloudflog.com/api/payments/save-stripe-info/",
        infoFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.message === "Success") {
          // setBackdrop(false);
          // toggleSuccessModal();
          // toggleModal();
          // alert(res.data.data.extra_msg);
          // history.push("/userDetails");
          if (amount > 0) {
            dispatch(
              createPayment(
                paymentFormData,
                history,
                setBackdrop,
                toggleSuccessModal
              )
            );
          } else {
            toggleSuccessModal();
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const createPayment =
  (paymentFormData, history, setBackdrop, toggleSuccessModal) => (dispatch) => {
    token = localStorage.getItem("accessToken");
    console.log("createPayment", paymentFormData);
    console.log("token", token);
    return axios
      .post(
        "https://admin.cloudflog.com/api/payments/createPayment/",
        paymentFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.message === "Success") {
          setBackdrop(false);
          // toggleModal();
          // alert(res.data.data.extra_msg);
          toggleSuccessModal();
          // dispatch({type: PAYMENT_MSG, payload: res.data.data.extra_msg});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const registerFreeTrial =
  (bodyFormData, toggleSuccessModal) => (dispatch) => {
    token = localStorage.getItem("accessToken");
    console.log("registerFreeTrial", bodyFormData);
    console.log("token", token);
    return axios
      .post(
        "https://admin.cloudflog.com/api/payments/free_trial/",
        bodyFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          // setBackdrop(false);
          // toggleModal();
          // alert(res.data.data.extra_msg);
          toggleSuccessModal();
          // dispatch({type: PAYMENT_MSG, payload: res.data.data.extra_msg});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const onPaypalSuccess = (body, toggleSuccessModal) => (dispatch) => {
  token = localStorage.getItem("accessToken");
  console.log("onPaypalSuccess", body);
  console.log("token", token);
  return axios
    .post("https://admin.cloudflog.com/api/payments/paymentUpdate/", body, {
      headers: {
        Authorization: `Bearer ${token}`,
        // 'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      console.log(res);
      toggleSuccessModal();
      if (res.data.status) {
        // setBackdrop(false);
        // toggleModal();
        // alert(res.data.data.extra_msg);
        // toggleSuccessModal();
        // dispatch({type: PAYMENT_MSG, payload: res.data.data.extra_msg});
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getPlanDetails = () => (dispatch) => {
  dispatch({ type: FETCH_PLAN_DETAILS });
  token = localStorage.getItem("accessToken");
  console.log("onPaypalSuccess");
  console.log("token", token);
  return axios
    .get("https://admin.cloudflog.com/api/auth/getRoleList/", {
      headers: {
        Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhYmMiOjYsImlhdCI6MTY0NzI3MTU4NX0.uI-6LQs3tOxqDqkb56VrDKQQ-OsbiHTempSWAVP7dOk`,
      },
    })
    .then((res) => {
      dispatch({ type: FETCH_PLAN_DETAILS_SUCCESS, payload: res.data });
      console.log(res.data);
    })
    .catch((err) => {
      dispatch({ type: FETCH_PLAN_DETAILS_FAILURE, payload: err });
    });
};
