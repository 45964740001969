import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Hidden,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import ZhLandingPageAppBar from "../components/ZhLandingPageAppBar";
import { Image } from "semantic-ui-react";
import TextField from "@material-ui/core/TextField";
import image1 from "../assets/images/register-screen.svg";
import { useDispatch } from "react-redux";
import { validateOtp } from "../actions/user";
import { useHistory } from "react-router-dom";
import ZhRegistrationForm from "../forms/ZhRegistrationForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
    overflow: "hidden",
  },
  sectionContainer: {
    display: "flex",

    [theme.breakpoints.down("sm")]: {
      minHeight: "80vh",
      alignItems: "center",
      textAlign: "center",
    },
    justifyContent: "center",
    padding: "5% 10%",
    // paddingTop: '',
  },
  FormSection: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      textAlign: "center",
      padding: "5%",
      paddingTop: 0,
    },
  },
  ImageSection: {
    display: "flex",
    // alignItems: "flex-start",
    // margin: '5%',
    [theme.breakpoints.down("sm")]: {},
  },
  DashImage: {
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      position: "relative",
    },
  },
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "100%",
    lineHeight: "27px",
    color: theme.palette.primary.main,
    margin: "2% 0",
    [theme.breakpoints.down("sm")]: {},
  },
  button: {
    width: "50%",
    color: theme.palette.defaultWhite.main,
    borderRadius: "20px",
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  anchor: {
    textDecoration: "none",
    // order: 3,
  },
  requestDemoMarginBottom: {
    marginBottom: "3em",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1em",
    },
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "300%",
    color: theme.palette.primary.main,
    lineHeight: "150%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10vw",
    },
  },
  phoneInputField: {
    margin: "5% 0",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Main = () => {
  const classes = useStyles();
  const [submitting, setSubmitting] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const phoneNumber = localStorage.getItem("phoneNumber");
  // console.log(phoneNumber);

  React.useEffect(() => {
    sessionStorage.getItem("validSession")
      ? sessionStorage.getItem("validSession")
      : history.push("/404");
  }, []);

  return (
    <Grid>
      <ZhLandingPageAppBar registration={true} />
      <Grid className={classes.root}>
        <Grid className={classes.sectionContainer}>
          <Grid item xs={12} md={7} className={classes.FormSection}>
            <Grid>
              <Typography gutterBottom className={classes.mainText}>
                注册
              </Typography>
            </Grid>
            <ZhRegistrationForm />
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} md={6} className={classes.ImageSection}>
              <Image
                // fluid
                src={image1}
                className={classes.DashImage}
              />
            </Grid>
          </Hidden>
          <Backdrop className={classes.backdrop} open={submitting}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Main;
