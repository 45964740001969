import React from "react";
import {
  Grid,
  Button,
  Hidden,
  Backdrop,
  Typography,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";

import { Image } from "semantic-ui-react";
import { validateOtp } from "../actions/user";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import image1 from "../assets/images/otp-screen.svg";
import { useDispatch, useSelector } from "react-redux";
import LandingPageAppBar from "../components/LandingPageAppBar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
  },
  sectionContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      minHeight: "80vh",
      alignItems: "center",
      textAlign: "center",
    },
    justifyContent: "center",
    padding: "10%",
    // paddingLeft: '20%'
  },
  FormSection: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      textAlign: "center",
      padding: "5%",
      paddingTop: 0,
    },
  },
  ImageSection: {
    display: "flex",
    // alignItems: "flex-start",
    // margin: '5%',
    [theme.breakpoints.down("sm")]: {},
  },
  DashImage: {
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      position: "relative",
    },
  },
  captionText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "100%",
    lineHeight: "27px",
    color: theme.palette.primary.main,
    margin: "2% 0",
    [theme.breakpoints.down("sm")]: {},
  },
  button: {
    width: "50%",
    fontSize: 16,
    fontWeight: 600,
    borderRadius: "20px",
    color: theme.palette.defaultWhite.main,
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  anchor: {
    textDecoration: "none",
    // order: 3,
  },
  requestDemoMarginBottom: {
    marginBottom: "3em",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1em",
    },
  },
  mainText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: "300%",
    color: theme.palette.primary.main,
    lineHeight: "150%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10vw",
    },
  },
  phoneInputField: {
    margin: "5% 0",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Main = () => {
  const classes = useStyles();
  const [otp, setOtp] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const phoneNumber = localStorage.getItem("phoneNumber");
  const email = localStorage.getItem("email");
  const store = useSelector((state) => state);
  // console.log(phoneNumber);
  const isLogin = store.userReducer.isLogin;

  React.useEffect(() => {
    sessionStorage.getItem("validSession")
      ? sessionStorage.getItem("validSession")
      : history.push("/404");
  }, []);

  const handleSubmit = () => {
    console.log("Get ID::::", email);
    var bodyFormData = new FormData();
    if (email == undefined || email == "") {
      bodyFormData.append("mobile", phoneNumber);
    } else {
      bodyFormData.append("mobile", email);
    }
    bodyFormData.append("otp", otp);

    // let raw = {
    //   mobile: JSON.parse(email),
    //   otp: otp,
    // };

    dispatch(validateOtp(bodyFormData, setSubmitting, history, isLogin));
  };

  return (
    <Grid>
      <LandingPageAppBar registration={true} />
      <Grid className={classes.root}>
        <Grid className={classes.sectionContainer}>
          <Grid item xs={12} md={7} className={classes.FormSection}>
            <Grid>
              <Typography gutterBottom className={classes.mainText}>
                OTP
              </Typography>
            </Grid>
            <Grid>
              <Typography className={classes.captionText}>
                Enter the code we have sent you on your registered phone no.
              </Typography>
            </Grid>
            <Grid className={classes.requestDemoMarginBottom}>
              <TextField
                required
                autoFocus
                value={otp}
                label="Enter OTP"
                className={classes.phoneInputField}
                onChange={(event) => setOtp(event.target.value)}
              />
            </Grid>
            <Grid>
              <a
                // href={"#virucontact"}
                className={classes.anchor}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  className={classes.button}
                >
                  Verify
                </Button>
              </a>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} md={6} className={classes.ImageSection}>
              <Image
                // fluid
                src={image1}
                className={classes.DashImage}
              />
            </Grid>
          </Hidden>
          <Backdrop className={classes.backdrop} open={submitting}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Main;
